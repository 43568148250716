import React from 'react';
import {Controller, useForm} from 'react-hook-form'
import {IMaskInput} from 'react-imask'

import s from './styles.module.css'
import Button from "../../../../shared/UI/Button/Button";
import {viewerAPI} from "../../../../shared/api/api";
import {useDispatch} from "react-redux";
import {sendCallToViewer} from "../../../../entities/Viewer/Model/viewer";

const LoginWithSms = ({changeModalType}) => {

    const dispatch = useDispatch()

    const loginWithSMSHandler = async (data) => {
        let responseElem;

        if (responseElem = document.querySelector('.g_recaptcha_response'))
        {
            let response = responseElem.value;
            data.recaptcha_resp = response;
        }
        var t;
        try 
        {
            const isUserExist = await viewerAPI.isUserExist(data.phone)
            switch (isUserExist.data.message) {
                case 'success':
                    await setCookie('recaptcha_resp', data.recaptcha_resp);
                    await dispatch(t = sendCallToViewer( {phone:data.phone, recaptcha_resp:data.recaptcha_resp} ));
                    changeModalType('Проверка входа')
                    break;
                case 'not found':
                    await setCookie('recaptcha_resp', data.recaptcha_resp);
                    await dispatch(sendCallToViewer( {phone:data.phone, recaptcha_resp:data.recaptcha_resp} ));
                    changeModalType('Проверка входа')
                    break;
                default:
                    alert('Извините, произошла ошибка!')
                    break;
            }
            console.log(t);
        } 
        catch (e) 
        {
            alert(e);
        }
    }

    function setCookie(name, value) 
    {
        let updatedCookie = name + "=" + value + ";max-age=120;path=/;";
        
        document.cookie = updatedCookie;
    }

    const {
        handleSubmit,
        control,
        formState: { errors,
                    isValid }
    } = useForm({
        defaultValues: {
            phone: '',
            recaptcha_resp: ''
        }
    })

    return (
        <>
            <div className={s.loginWithSMS__wrap}>
                <form onSubmit={handleSubmit(loginWithSMSHandler)}>
                    <div>
                        <p className={s.labelP}>Номер телефона</p>
                        <div className={s.inputWrap}>
                            <Controller
                                name="phone"
                                control={control}
                                rules={{
                                    pattern: {
                                        value: /(^8|7|\+7)((\d{10})|(\s\(\d{3}\)\s\d{3}\s\d{2}\s\d{2}))/,
                                        message: 'Введите корректный номер телефона'
                                    },
                                    required: {
                                        value: true,
                                        message: 'Это поле необходимо заполнить.'
                                    }
                                }}
                                render={({ field }) => (
                                    <IMaskInput
                                        mask={'+{7} (#00) 000-00-00'}
                                        definitions={{ '#': /9/ }}
                                        value={field.value}
                                        onAccept={value => field.onChange(value.replace(/\D/g, ''))}
                                        placeholder={'+7'}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className={s.sendCodeBtn__wrap}>
                        <Button
                            text={'Отправить код'}
                            isDisabled={!isValid}
                            type={'submit'}
                        />
                    </div>
                    <input type="hidden" className='g_recaptcha_response' name="g_recaptcha_response"></input>
                </form>
            </div>

            <div className={s.textInfo}>
                Продолжая, вы соглашаетесь <a href='/about/personal-data-policy/' className={s.textInfoLink}>со сбором и обработкой персональных данных и пользовательским соглашением </a>
            </div>
        </>
    );
};

export default LoginWithSms;